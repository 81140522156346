export const VARIANTS_ORDER = ['headline', 'display', 'standard'];

export const SIZES_ORDER = ['standard', 'micro-plus', 'headline'];

export const WIDTHS_ORDER = [
  'wide',
  'normal',
  'upright',
  'slanted',
  'backslanted',
  'compact',
  'narrow',
  'condensed',
  'extra-condensed',
  'compressed',
];

export const WEIGHTS_ORDER = [
  'hairline',
  'thin',
  'extra-light',
  'light',
  'book',
  'regular',
  'medium',
  'semi-bold',
  'bold',
  'black',
  'ultra',
  'i',
  'ii',
  'iii',
];

export const STYLES_ORDER = ['roman', 'italic', 'solid', 'inline', 'engraved'];

export const LICENSE_TYPES_ORDER = ['desktop', 'web', 'mobile'];
